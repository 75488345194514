//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { i18n } from '@/services/i18n.init';

const ENUM_OPTIONS = [
  { label: i18n.t('events.my.filter.place_online'), value: 'ONLINE' },
  { label: i18n.t('events.my.filter.place_physical'), value: 'IN_PERSON' },
];

export default {
  name: 'VenueEnumFilter',
  props: ['prefill'],
  components: {},
  data() {
    return {
      venueId: null,
      venueOptions: ENUM_OPTIONS,
    };
  },
  methods: {},
  mounted() {
    this.venueId = this.prefill;
  },
  created() {},
  watch: {
    venueId(newval) {
      this.$emit('venueChange', newval);
    },
    prefill(newval) {
      this.venueId = newval;
    },
  },
};
